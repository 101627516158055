<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="editForm" ref="editForm" label-width="100px" :rules="editRules">
        <el-form-item label="提示：" >
          <div style="color:red">添加违禁词时，每个词用英文逗号隔开</div>
        </el-form-item>
        <el-form-item label="关键词：" prop="keywords">
          <el-input type="textarea" :rows="20" class="w-600 arrList" placeholder="请输入内容" v-model="editForm.keywords"> </el-input>
        </el-form-item>
        <div class="d-flex mt-20 mb-25" style="margin-left: 630px;">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SysCountyCircle",
  data() {
		var checkkeywords = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('关键字不能为空'));
			}
			if (!value.match(/^[\w,]+$/)) {
				return callback(new Error('关键词间请用英文逗号(,)隔开'));
			}
		};
    return {
      table: {url: '/countycircle/index', where: {}},  // 表格配置
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
				keywords: [{
					required: true,
					message: '关键字不能为空',
					trigger: 'blur'
				},{
					pattern: /^([\u4e00-\u9fa5_a-zA-Z0-9]+[,])*[\u4e00-\u9fa5_a-zA-Z0-9]+$/, 
					message: '关键词间请用英文逗号(,)隔开', 
					trigger: 'blur',
				}],
      },
    }
  },
  created(){
    const loading = this.$loading({lock: true, target: document.querySelector(".arrList")});
    this.$http.post('/Countycircle/set_keyworlds').then(res => {
      loading.close();
      let data=res.data
      this.editForm=data.data
		})
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    /**
     * 保存编辑
     */
    save() {
			//console.log(this.editForm.keywords)
				//console.log(this.editForm.keywords.match(/^([a-zA-Z0-9\\u4e00-\\u9fa5]+[,|，])*[a-zA-Z0-9\\u4e00-\\u9fa5]+$/))
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						// if(this.editForm.keywords.match(/^[\w,]+$/)){
							
						// }
						const loading = this.$loading({lock: true, target: document.querySelector(".arrList")});
						this.$http.post('/Countycircle/set_keyworlds', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.$message({type: 'success', message: res.data.msg});
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
    },
	}
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
</style>